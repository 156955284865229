import { createGlobalStyle, css } from 'styled-components'
import { normalize } from 'styled-normalize'

const globalNoLayoutCss = css`
    ${normalize}

    @font-face {
        font-family: 'Manrope';
        src: url('/rental/Manrope-VariableFont_wght.ttf') format('truetype-variations');
        font-weight: 100 800;
        font-style: normal;
        font-display: swap;
        font-stretch: 0% 100%;
        font-optical-sizing: 0 100;
    }

    @font-face {
        font-family: 'Inter';
        src: url('/rental/Inter.ttf') format('truetype-variations');
        font-weight: 100 800;
        font-style: normal;
        font-display: swap;
        font-stretch: 0% 100%;
        font-optical-sizing: 0 100;
    }

    html,
    body {
        font-family: 'Manrope', Helvetica, sans-serif;
        color: #484848;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: inherit;
        height: 100vh;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
`

export const GlobalNoLayoutStyle = createGlobalStyle`${globalNoLayoutCss}`
